import React from "react";
import { array, string } from "prop-types";
import { AppLogo } from "../app-logo";
import { Link } from "@quintype/components";
//import { WeatherWidget } from "../weather-widget";
import { Login } from "../navbar-login";
import { Logout } from "../navbar-logout";
import "./top-header.m.css";
import ErrorMessage from "../../atoms/error-message";
import useStorage from "../../../hook/useStorage";

const TopHeaderInfo = ({ pageType }) => {
  const [checkUser] = useStorage()

  return (
    <div styleName="logo-row">
      <div styleName="logo">
          <AppLogo />
      </div>
      {/*<div styleName="weather-desktop">
        <WeatherWidget/>
      </div>*/}
      <div styleName="top-header-login">
        {checkUser&&<Logout/>}
        {!checkUser&&<Login />}
        <div styleName="tablet-and-mobile-error-message"><ErrorMessage /></div>
      </div>
   </div>
  );
};

const TopHeaderLinks = ({ topHeaderLinks }) => {
  return (
    <ul>
      {topHeaderLinks.length >= 1 &&
        topHeaderLinks.map(item => {
          return item.isExternalLink && !(item.completeUrl.includes("jarviseutu-lehti.fi")) ? (
            <li styleName="topnav-li" key={item.title}>
              <a styleName="topnav-item" href={item.completeUrl} target="_blank">
                {item.title}
              </a>
            </li>
          ) : (
            <li styleName="topnav-li" key={item.title}>
              <Link styleName="topnav-item" href={item.completeUrl || "/"}>
                {item.title}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

const TopHeaderBase = props => {
  return (
    <div>
      {/*<div styleName="first-child">why?</div>*/}
      <TopHeaderInfo pageType={props.pageType} />
      <hr/>
      <div styleName="top-nav">
        <TopHeaderLinks topHeaderLinks={props.topHeaderLinks} />
        <div styleName="topnav-li topnav-error">
          <ErrorMessage />
        </div>
      </div>
    </div>

  );
};

TopHeaderLinks.propTypes = {
  topHeaderLinks: array
};

TopHeaderInfo.propTypes = {
  pageType: string
};

TopHeaderBase.propTypes = {
  pageType: string,
  topHeaderLinks: array
};

const TopHeader = (TopHeaderBase);

export default TopHeader;
