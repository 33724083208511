import { useEffect, useState } from "react";
import axios from "axios";
import isbot from 'isbot';
const useUser = () => {
  const [chkUsr, setCheckUser] = useState(false || "false");
  const [hasActiveProduct, setHasActiveProduct] = useState(false);
  const [anonSession, setAnonSession] = useState(false);

  useEffect(() => {
    if(!isbot(window.navigator.userAgent)){
      anonSession?fetchAPI():checkAnonSession();
    }else{
      return;
    }
  }, [chkUsr, anonSession, hasActiveProduct]);

  const checkAnonSession = async () => {
    await axios("/tulo/checkAnonSession").then(res => {
      if (!res.data.isAnonSessAvailable) {
        window.location = "/tulo/performIdentify";
      } else {
        const anonSess = res.data.isAnonSessAvailable;
        setAnonSession(anonSess);
      }
    });
  };
  async function fetchAPI() {
    await axios("/tulo/isLoggedIn").then(res => {
      if (typeof window === "undefined") {
      } else {
        JSON.stringify(window.localStorage.setItem("userValues", res.data.isLoggedIn));
        const userValues = JSON.parse(window.localStorage.getItem("userValues"));
        setCheckUser(userValues);
        const userId = (res.data["active_products"] && res.data["active_products"].item.id) || "";
        JSON.stringify(window.localStorage.setItem("userId", userId));
        const activeProductNames = (res.data["active_products"] && res.data["active_products"].item["active_products"]) || [];
        JSON.stringify(window.localStorage.setItem("activeProductNames", activeProductNames));
        const activeProducts = (res.data.active_products && res.data.active_products.item.active_products) || [];
        const isPakaDigi = activeProducts.includes("js_digi");
        const checkCondition = isPakaDigi;
        JSON.stringify(window.localStorage.setItem("activeProducts", checkCondition));
        setHasActiveProduct(checkCondition);
      }
    });
  }
  return [chkUsr, hasActiveProduct];
};
export default useUser;
